<template>
    <div>
        <Horizontal ref="Horizontal" v-if="$store.getters.currentPageVersion === 'Horizontal'" @getPhoneCode="getPhoneCode"
            :codecd="codecd" @subForm="subForm"></Horizontal>
        <Vertical ref="Vertical" v-else @getPhoneCode="getPhoneCode" :codecd="codecd" @subForm="subForm"></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Mine/PayPassword/Horizontal/Index.vue'
    import Vertical from '@/components/Mine/PayPassword/Vertical/Index.vue'

    export default {
        components: {
            Horizontal,
            Vertical
        },
        data() {
            return {
                codecd: 0,
                isCanSub: true,
                isTabBtn: 0
            }
        },
        created(){
            if (!this.$store.state.bindPhone) {
                this.$Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    overlay:false,
                    message: '手机号获取失败，正在跳转至绑定手机页',
                });
                setTimeout(()=> {
                    this.$Toast.clear();
                    this.$router.push({
                        name:'BindPhoneIndex'
                    })
                }, 2000)
            }
        },
        methods: {
            getPhoneCode() {
                if(this.$common.timeFunc().timeStampS- this.isTabBtn <7) {
                    this.$Toast({
                        message: '请勿频繁操作',
                    })
                    return
                }else {
                    this.isTabBtn = this.$common.timeFunc().timeStampS
                }
                if (!this.$store.state.bindPhone) {
                    this.$Toast({
                        message: '手机号获取失败',
                    })
                    return
                }

                let that = this,
                    requestMethods = this.$store.state.hasPayPassword === 1 ? 'getChangePayPasswordCode' :
                    'getSetPayPasswordCode',
                    requestData = {
                        user_id: this.$store.state.userId,
                        token: this.$store.state.token,
                        code_type: this.$store.state.hasPayPassword === 1 ? 'sdk_modify_pay_password' :
                            'sdk_bind_pay_password'
                    }
                eval(`this.$api.user.${requestMethods}(${JSON.stringify(requestData)})`).then(res => {
                    if (res.data.code === 200) {
                        this.codecd = 60
                        let cdTimer = setInterval(() => {
                            that.codecd = that.codecd - 1
                            if (that.codecd < 1) {
                                clearInterval(cdTimer)
                            }
                        }, 1000)
                        this.$Toast({
                            message: '验证码已发送',
                        })
                    }
                })
            },

            subForm(data) {
                if (!this.checkRequestData(data)) {
                    return
                }
                this.isCanSub = false
                setTimeout(() => {
                    this.isCanSub = true
                }, 5000)
                let requestMethods = this.$store.state.hasPayPassword === 1 ? 'changePayPassword' : 'setPayPassword',
                    requestData = {
                        user_id: this.$store.state.userId,
                        token: this.$store.state.token,
                        phone_code: data.inputCode,
                        pay_password: data.inputPassword
                    },
                    successMsg = this.$store.state.hasPayPassword === 1 ? '密码修改成功！' : '密码设置成功！'

                eval(`this.$api.user.${requestMethods}(${JSON.stringify(requestData)})`).then(res => {
                    if (res.data.code === 200) {
                        if(this.$store.state.hasPayPassword !== 1) {
                            this.$store.commit('getPayPasswordSetState', 1)
                        }
                        this.$Toast({
                            message: successMsg,
                        })
                        setTimeout(() => {
                            this.$router.replace({
                                name: 'MineIndex'
                            })
                        }, 2000)
                    }
                })
            },
            checkRequestData(data) {
                console.log(data)
                if (!data.inputPassword || !data.inputCode) {
                    this.$Toast({
                        message: '密码和验证码不能为空',
                    })
                    return false
                }
                if (!this.isCanSub) {
                    this.$Toast({
                      	 message: '请勿频繁操作',
                    })
                    return false
                }
                if (data.inputPassword.length !== 6 ||
                    data.inputPassword.indexOf(" ") !== -1 ||
                    data.inputCode.length !== 6 ||
                    data.inputCode.indexOf(" ") !== -1) {
                    this.$Toast({
                        message: '密码或验证码格式不正确',
                    })
                    return false
                }
                return true
            },
        }
    }
</script>

<style>
</style>
